import React, { useState, useContext, useEffect, useLayoutEffect } from "react"
import { navigate } from "gatsby"

import { Card } from "../card/card"
import { Link } from "../utils/utils"
import { CardSlider } from "../card-slider/card-slider"
// import backsound from "../../assets/audio/smart-move-jingle.mp3"
// import { Button } from "../buttons/buttons"

const NavbarDesktop = ({
  brandLogoMain,
  brandLogoSecondary,
  brandLogoAlt,
  navBackground,
  url,
  fetchedData,
  lang = "EN",
  newsLangSlug,
  isDd,
  isAmp,
  isApartemen = false,
  soundFile,
}) => {
  const menuItems =
    fetchedData.menus?.nodes[lang === "EN" ? 2 : 3]?.menuItems?.nodes
  const propertyTypes = fetchedData.propertyTypes.nodes
  const urlArr = url ? url.split("/") : null

  const setToID = async () => {
    if (newsLangSlug) {
      if (lang !== "ID") {
        window.location.href = `/id/news/${newsLangSlug}`
      }
    } else {
      if (document.location.pathname.includes("/id")) {
        await navigate(document.location.pathname)
      } else {
        await navigate(`/id${document.location.pathname}`)
      }
    }
  }
  const setToEN = async () => {
    let pathname = document.location.pathname
    let enPath = pathname.split("/id").join("")

    if (newsLangSlug) {
      if (lang !== "EN") {
        window.location.href = `/news/${newsLangSlug}`
      }
    } else {
      navigate(`${enPath}`)
    }
  }

  const investorsItems = menuItems.find(x => x.label === "Investors")
    ?.childItems.nodes
  const investorsItemsId = menuItems.find(x => x.label === "Investor")
    ?.childItems.nodes
  const corporateItems = menuItems.find(x => x.label === "Corporate")
    ?.childItems.nodes
  const corporateItemsId = menuItems.find(x => x.label === "Perusahaan")
    ?.childItems.nodes

  let developmentItems = []

  let menuListChecker = []

  menuItems.map((item, i) => {
    menuListChecker.push(item.label.toLowerCase())
  })

  for (let i = propertyTypes.length - 1; i >= 0; i--) {
    switch (propertyTypes[i].slug) {
      case "ecatalog":
        developmentItems[0] = (
          <Card
            key={i}
            variant="overlay"
            title={propertyTypes[i].name}
            titleClassName="h5 text-truncate"
            text={propertyTypes[i].acfPropertyType.excerpt}
            textClassName="font-size-sm"
            img={propertyTypes[i].acfPropertyType.image.sourceUrl}
            link={`https://ecatalog.sinarmasland.com`}
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "township":
        developmentItems[1] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "residential":
        developmentItems[2] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "office":
        developmentItems[3] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "retail":
        developmentItems[4] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "hospitality":
        developmentItems[5] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "commercial":
        developmentItems[6] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "industrial":
        developmentItems[7] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      case "kavling":
        developmentItems[8] = (
          <Card
            key={i}
            variant="overlay"
            title={
              lang === "EN"
                ? propertyTypes[i].name
                : propertyTypes[i]?.translation?.name || propertyTypes[i].name
            }
            titleClassName="h5 text-truncate"
            text={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.excerpt
                : propertyTypes[i].translation?.acfPropertyType?.excerpt ||
                  propertyTypes[i].acfPropertyType.excerpt
            }
            textClassName="font-size-sm"
            img={
              lang === "EN"
                ? propertyTypes[i].acfPropertyType.image.sourceUrl
                : propertyTypes[i].translation?.acfPropertyType?.image
                    ?.sourceUrl ||
                  propertyTypes[i].acfPropertyType.image.sourceUrl
            }
            link={
              lang === "EN"
                ? `/development/${propertyTypes[i].slug.toLowerCase()}/`
                : `/id/development/${propertyTypes[i].slug.toLowerCase()}/`
            }
            imgHeight="h-ratio-3-2"
            imgOverlay="40"
            className="card-nav"
          />
        )
        break
      default:
        break
    }
  }

  const [navbarSearch, setNavbarSearch] = useState({
    initial: false, // to change background navbar
    clicked: false, // Open / close form search
  })

  const [developmentToggle, setDevelopmentToggle] = useState({
    clicked: false,
  })

  const [corporateToggle, setCorporateToggle] = useState({
    clicked: false,
  })

  const [investorToggle, setInvestorToggle] = useState({
    clicked: false,
  })

  const [soundToggle, setSoundToggle] = useState({
    clicked: false,
  })

  const [audio] = useState(typeof Audio !== "undefined" && new Audio(soundFile))

  const handleClick = () => {
    if (navbarSearch.clicked === false) {
      setNavbarSearch({ clicked: true, intial: true })
    } else {
      setNavbarSearch({ clicked: false, intial: false })
    }
  }

  const handleDevelopmentClick = () => {
    if (developmentToggle.clicked === false) {
      setDevelopmentToggle({ clicked: true })
    } else {
      setDevelopmentToggle({ clicked: false })
    }
  }

  const handleCorporateClick = () => {
    if (corporateToggle.clicked === false) {
      setCorporateToggle({ clicked: true })
    } else {
      setCorporateToggle({ clicked: false })
    }
  }

  const handleInvestorClick = () => {
    if (investorToggle.clicked === false) {
      setInvestorToggle({ clicked: true })
    } else {
      setInvestorToggle({ clicked: false })
    }
  }

  const handleSoundClick = () => {
    if (soundToggle.clicked === false) {
      setSoundToggle({ clicked: true })
      audio.play()
      audio.loop = true
    } else {
      setSoundToggle({ clicked: false })
      audio.pause()
    }
  }

  const parseLink = url => {
    if (url) {
      const splitUrl = url.split("http")
      if (splitUrl.length > 1) {
        return url
      } else {
        return `${lang === "EN" ? "/" : "/id/"}investors${url}`
      }
    }
  }

  const getHomeLink = () => {
    if (urlArr && urlArr.length > 1) {
      const path = urlArr[1]
      if (path === "wishforhome") {
        return lang === "EN" ? "/" : "/id/" + urlArr[1]
      } else {
        return lang === "EN" ? "/" : "/id"
      }
    } else {
      return lang === "EN" ? "/" : "/id/"
    }
  }

  useEffect(() => {
    const listMenuHeader = document.querySelectorAll(
      ".navbar-nav .nav-item.nav-desktop .nav-link"
    )
    const listMenuDd = document.querySelectorAll(
      ".bd-page .navbar-nav-main .nav-item .nav-link"
    )
    const listMenuBefore = document.querySelectorAll(
      ".bd-page .navbar-nav-main .nav-item .nav-link:before"
    )
    const listMenuHover = document.querySelectorAll(
      ".bd-page .navbar-nav-main .nav-item:hover>a.nav-link"
    )
    if (isDd === undefined) {
      if (listMenuHeader.length > 0) {
        for (var i = 0; i < listMenuHeader.length; i++) {
          lang === "EN"
            ? (listMenuHeader[i].style.marginRight = "0.7rem")
            : (listMenuHeader[i].style.marginRight = "0.5rem")
        }
      }
    } else if (isDd) {
      // document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      // document.getElementsByClassName("logo-main")[0].style.display = `block`
      // if (listMenuDd.length > 0) {
      //   for (var i = 0; i < listMenuDd.length; i++) {
      //     listMenuDd[i].style.color = "#333"
      //   }
      // }
      if (listMenuBefore.length > 0) {
        for (var i = 0; i < listMenuBefore.length; i++) {
          listMenuBefore[i].style.backgroundColor = "#ab0204"
        }
      }
      if (listMenuHover.length > 0) {
        for (var i = 0; i < listMenuHover.length; i++) {
          listMenuHover[i].style.color = "#ab0204"
        }
      }
    }
  }, [])

  return (
    <div id="navbarDesktop" className="overflow-x-hidden">
      {isAmp ? (
        <header>
          <amp-mega-menu height="60" layout="fixed-height">
            <nav
              id="desktopNav"
              className={`navbar navbar-nav-main ${navBackground} ${
                navbarSearch.clicked ? "is-scrolled" : ""
              } fixed-top navbar-expand-lg d-none d-lg-flex`}
            >
              <ul className="navbar-nav navbar-nav-main ml-auto">
                <li>
                  <Link className="navbar-brand" to={getHomeLink()}>
                    <amp-img
                      class="logo-main img-fluid"
                      src={brandLogoMain}
                      alt={brandLogoAlt}
                      // layout="responsive"
                      width="152"
                      height="32"
                    ></amp-img>
                    {/* <amp-img
                      className="logo-secondary img-fluid"
                      src={brandLogoSecondary}
                      alt={brandLogoAlt}
                      // layout="responsive"
                      width="152"
                      height="32"
                    ></amp-img> */}
                  </Link>
                </li>
                {!navbarSearch.clicked &&
                  menuItems.map((item, i) => {
                    const activeState = urlArr
                      ? urlArr[1] === item.label.toLowerCase() ||
                        ((urlArr[1] === "about-us" ||
                          urlArr[1] === "career" ||
                          urlArr[1] === "partner") &&
                          item.label === "Corporate")
                        ? "active-path"
                        : ""
                      : ""

                    return i === 0 ? (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${
                          urlArr ? (urlArr[1] === "" ? "active-path" : "") : ""
                        }`}
                        id={item.label}
                      >
                        <Link
                          className="nav-link"
                          to={`${lang === "EN" ? item.url : `/id${item.url}/`}`}
                        >
                          {item.label}
                        </Link>
                      </li>
                    ) : item.childItems.nodes.length > 0 ||
                      item.label === "Development" ||
                      item.label === "Pembangunan" ||
                      item.label === "Corporate" ||
                      item.label === "Perusahaan" ? (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${activeState}`}
                        id={item.label}
                        data-target={`#${item.label}`}
                      >
                        <a className="nav-link">{item.label}</a>
                      </li>
                    ) : (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${activeState}`}
                        id={item.label}
                      >
                        <a
                          className="nav-link"
                          href={`${
                            lang === "EN"
                              ? item.url
                              : item.label.toLowerCase() === "e-catalog" ||
                                item.label.toLowerCase() === "double dream" ||
                                item.label.toLowerCase() === "smart move"
                              ? item.url
                              : `/id${item.url}`
                          }/`}
                        >
                          {item.label}
                        </a>
                      </li>
                    )
                  })}
                <li className="nav-item nav-item-search position-relative">
                  <a className="nav-link nav-link-icon">
                    <i
                      className={
                        navbarSearch.clicked ? "fal fa-times" : "fal fa-search"
                      }
                      onClick={handleClick}
                    ></i>
                  </a>
                  {/* <form
                    action={`${lang === "EN" ? "" : "/id"}/search-result`}
                    className={
                      navbarSearch.clicked ? "form-search is-open" : "form-search"
                    }
                    target="_top"
                  >
                    <input
                      type="text"
                      name="key"
                      id="key"
                      className="form-control"
                      placeholder="Search"
                    />
                  </form> */}
                </li>
                <li className="nav-item nav-desktop ml-3">
                  <Link
                    className="btn btn-primary nav-primary pb-0 pt-1 login"
                    to="/login/"
                  >
                    Login
                  </Link>
                </li>
                {/* <li className="nav-item nav-desktop d-flex align-items-center">
                  <div className="nav-separator" role="dialog">
                    <div className="v-line"></div>
                  </div>
                  <div className="d-flex lang-switch" role="dialog">
                    <div
                      onClick={setToEN}
                      className={`mr-2 lang-item ${
                        lang === "EN" ? "active" : ""
                      }`}
                    >
                      EN
                    </div>
                    <div
                      onClick={setToID}
                      className={`lang-item ${lang === "ID" ? "active" : ""}`}
                    >
                      ID
                    </div>
                  </div>
                </li> */}
              </ul>
            </nav>
          </amp-mega-menu>
        </header>
      ) : (
        <nav
          id="desktopNav"
          className={`navbar navbar-nav-main ${navBackground} ${
            navbarSearch.clicked ||
            developmentToggle.clicked ||
            corporateToggle.clicked ||
            investorToggle.clicked
              ? "is-scrolled"
              : ""
          } fixed-top navbar-expand-lg d-none d-lg-flex`}
        >
          <div
            className={`container 
            ${
              menuListChecker.includes("career") ||
              menuListChecker.includes("karir")
                ? "wcok"
                : ""
            }`}
          >
            <Link className="navbar-brand" to={getHomeLink()}>
              <img
                className="logo-main"
                src={brandLogoMain}
                alt={brandLogoAlt}
              />
              <img
                className="logo-secondary"
                src={brandLogoSecondary}
                alt={brandLogoAlt}
              />
            </Link>
            <div className="navbar-collapse">
              <ul className="navbar-nav navbar-nav-main ml-auto">
                {!navbarSearch.clicked &&
                  menuItems.map((item, i) => {
                    const activeState = urlArr
                      ? urlArr[1] === item.label.toLowerCase() ||
                        ((urlArr[1] === "about-us" ||
                          urlArr[1] === "career" ||
                          urlArr[1] === "partner") &&
                          item.label === "Corporate")
                        ? "active-path"
                        : ""
                      : ""

                    return i === 0 ? (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${
                          urlArr ? (urlArr[1] === "" ? "active-path" : "") : ""
                        }`}
                        id={item.label}
                      >
                        <a
                          className="nav-link"
                          href={`${
                            lang === "EN" ? item.url : `/id${item.url}/`
                          }`}
                        >
                          {item.label}
                        </a>
                      </li>
                    ) : item.childItems.nodes.length > 0 ||
                      item.label === "Development" ||
                      item.label === "Pembangunan" ||
                      item.label === "Corporate" ||
                      item.label === "Perusahaan" ? (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${activeState}`}
                        id={item.label}
                        data-target={`#${item.label}`}
                        onClick={
                          item.label === "Development" ||
                          item.label === "Pembangunan"
                            ? handleDevelopmentClick
                            : item.label === "Corporate" ||
                              item.label === "Perusahaan"
                            ? handleCorporateClick
                            : item.label === "Investors" ||
                              item.label === "Investor"
                            ? handleInvestorClick
                            : ""
                        }
                      >
                        <a className="nav-link" href="#">
                          {item.label}
                        </a>
                      </li>
                    ) : (
                      <li
                        key={`${item.label}-${i}`}
                        className={`nav-item nav-desktop ${activeState}`}
                        id={item.label}
                      >
                        <a
                          className="nav-link"
                          href={`${
                            lang === "EN"
                              ? item.url
                              : item.label.toLowerCase() === "e-catalog" ||
                                item.label.toLowerCase() === "double dream" ||
                                item.label.toLowerCase() === "smart move"
                              ? item.url
                              : `/id${item.url}`
                          }`}
                          target={
                            item.label.toLowerCase() === "e-catalog"
                              ? "_blank"
                              : "_self"
                          }
                        >
                          {item.label}
                        </a>
                      </li>
                    )
                  })}
                <li className="nav-item nav-item-search position-relative">
                  <a
                    className="nav-link nav-link-icon"
                    href="#"
                    aria-label="search-navbar"
                  >
                    <i
                      className={
                        navbarSearch.clicked ? "fal fa-times" : "fal fa-search"
                      }
                      onClick={handleClick}
                    ></i>
                  </a>
                  <form
                    action={`${lang === "EN" ? "" : "/id"}/search-result`}
                    className={
                      navbarSearch.clicked
                        ? "form-search is-open"
                        : "form-search"
                    }
                    target="_top"
                  >
                    <input
                      type="text"
                      name="key"
                      id="key"
                      className="form-control"
                      placeholder="Search"
                    />
                  </form>
                </li>
                <li className="nav-item nav-desktop ml-3">
                  <Link
                    className="btn btn-primary nav-primary pb-0 pt-1 login"
                    to="/login/"
                  >
                    Login
                  </Link>
                </li>
                {isApartemen && (
                  <li className="nav-item nav-desktop ml-3">
                    <button
                      className="btn btn-primary nav-primary pb-0 pt-1"
                      id="sound-btn"
                      onClick={() => handleSoundClick()}
                    >
                      <i
                        className={
                          soundToggle.clicked ? "fa fa-pause" : "fa fa-play"
                        }
                        id="play-pause-i"
                      ></i>
                    </button>
                  </li>
                )}
                <li
                  className="nav-item nav-desktop d-flex align-items-center"
                  id="lang-wrapper"
                >
                  <div className="nav-separator">
                    <div className="v-line"></div>
                  </div>
                  <div className="d-flex lang-switch">
                    <div
                      onClick={setToEN}
                      className={`mr-2 lang-item ${
                        lang === "EN" ? "active" : ""
                      }`}
                    >
                      EN
                    </div>
                    <div
                      onClick={setToID}
                      className={`lang-item ${lang === "ID" ? "active" : ""}`}
                    >
                      ID
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      )}
      {/* Megamenu Development */}
      {!isAmp && (
        <div
          id={menuItems[1].label}
          className={`megamenu-desktop d-none d-lg-block ${
            lang === "EN" && developmentToggle.clicked ? "show" : ""
          }`}
          data-content
        >
          <div className="container menu-area">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link">
                    {lang === "EN" ? "Development" : "Pembangunan"}
                  </span>
                </li>
              </ul>
              <div className="d-flex">
                <a
                  href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-link fx-underline mr-4"
                >
                  e-Catalog
                </a>
                <Link
                  to={lang === "EN" ? "/development/" : "/id/development/"}
                  className="btn btn-link fx-underline"
                >
                  {lang === "EN" ? "See All Projects" : "Lihat Semua Proyek"}
                </Link>
              </div>
            </div>
          </div>

          <div id="navSlider" className="container">
            <CardSlider noGutter>{developmentItems}</CardSlider>
          </div>
        </div>
      )}

      {/* Megamenu Development ID */}
      {!isAmp && (
        <div
          id="Pengembangan"
          className={`megamenu-desktop d-none d-lg-block ${
            lang === "ID" && developmentToggle.clicked ? "show" : ""
          }`}
          data-content
        >
          <div className="container menu-area">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link">Development</span>
                </li>
              </ul>
              <div className="d-flex">
                <a
                  href="https://ecatalog.sinarmasland.com?utm_source=Website&utm_medium=SMLWebsite&utm_campaign=Digital_e-Catalog_2021&utm_content=ECatalog_SML_Website_Menu"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-link fx-underline mr-4"
                >
                  e-Catalog
                </a>
                <Link
                  to={lang === "EN" ? "/development/" : "/id/development/"}
                  className="btn btn-link fx-underline"
                >
                  {lang === "EN" ? "See All Projects" : "Lihat Semua Projek"}
                </Link>
              </div>
            </div>
          </div>

          <div id="navSlider" className="container">
            <CardSlider noGutter>{developmentItems}</CardSlider>
          </div>
        </div>
      )}

      {/* Megamenu Corporate */}
      {!isAmp && (
        <div
          id={menuItems[2].label}
          className={`megamenu-desktop megamenu-corporate d-none d-lg-block ${
            lang === "EN" && corporateToggle.clicked ? "show" : ""
          }`}
          data-content
        >
          <div className="container">
            <div className="row row-5">
              <div className="col-5 menu-area border-right">
                <ul className="navbar-nav mb-3">
                  <li className="nav-item active">
                    <span className="nav-link">
                      {menuItems[2].childItems.nodes[0].label}
                    </span>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={lang === "EN" ? "/partner/" : "/id/partner/"}
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[1].label}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={lang === "EN" ? "/news/" : "/id/news/"}
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[2].label}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://events.sinarmasland.com/"
                      className="nav-link"
                    >
                      {menuItems[2].childItems.nodes[3].label}
                    </a>
                  </li>
                </ul>
                <Link
                  to={lang === "EN" ? "/career" : "/id/career"}
                  className="btn btn-outline-dark mb-3"
                >
                  <span>{menuItems[2].childItems.nodes[4].label}</span>
                </Link>
                <a
                  href="https://bigtrons.sinarmasland.com/Register"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-outline-dark"
                >
                  <span>{menuItems[2].childItems.nodes[5].label}</span>
                </a>
              </div>
              <div className="col-7 submenu-area">
                <ul className={`navbar-nav ${lang === "EN" ? "" : "d-none"}`}>
                  {corporateItems &&
                    corporateItems[0] &&
                    corporateItems[0].childItems.nodes.map((item, i) => {
                      return (
                        <div key={`submenu-${item.label}`} className="nav-item">
                          <Link
                            to={
                              lang === "EN" ? `${item.url}/` : `/id${item.url}/`
                            }
                            className="nav-link nav-link-corporate"
                            // onClick={navClick}
                          >
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                </ul>
                <ul className={`navbar-nav ${lang === "EN" ? "d-none" : ""}`}>
                  {corporateItemsId &&
                    corporateItemsId[0] &&
                    corporateItemsId[0].childItems.nodes.map((item, i) => {
                      return (
                        <div key={`submenu-${item.url}`} className="nav-item">
                          <Link
                            to={
                              lang === "EN" ? `${item.url}/` : `/id${item.url}/`
                            }
                            className="nav-link nav-link-corporate"
                            // onClick={navClick}
                          >
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Megamenu Corporate ID */}
      {/* <div
        id="Perusahaan"
        className="megamenu-desktop megamenu-corporate d-none d-lg-block"
        data-content
      >
        <div className="container">
          <div className="row row-5">
            <div className="col-5 menu-area border-right">
              <ul className="navbar-nav mb-3">
                <li className="nav-item active">
                  <span className="nav-link">Tentang</span>
                </li>
                <li className="nav-item">
                  <Link to="/partner" className="nav-link">
                    Partner
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/news" className="nav-link">
                    Berita
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://events.sinarmasland.com/"
                    className="nav-link"
                  >
                    Acara
                  </a>
                </li>
              </ul>
              <Link to="/career" className="btn btn-outline-dark mb-3">
                <span>Karir</span>
              </Link>
              <a
                href="https://bigtrons.sinarmasland.com/Register"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-dark"
              >
                <span>Pendaftaran Vendor</span>
              </a>
            </div>
            <div className="col-7 submenu-area">
              <ul className="navbar-nav">
                {corporateItemsId &&
                  corporateItemsId[0] &&
                  corporateItemsId[0].childItems.nodes.map((item, i) => {
                    return (
                      <div key={`submenu-${item.url}`} className="nav-item">
                        <Link
                          to={item.url}
                          className="nav-link nav-link-corporate"
                          // onClick={navClick}
                        >
                          {item.label}
                        </Link>
                      </div>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      {/* Megamenu Investors */}
      {!isAmp && (
        <div
          id={"Investors"}
          className={`megamenu-desktop d-none d-block ${
            lang === "EN" && investorToggle.clicked ? "show" : ""
          }`}
          data-content
        >
          <div className="container">
            <div className="row">
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItems &&
                    investorsItems.map((item, i) => {
                      if (i < 5) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? lang === "EN"
                                    ? `/investors/`
                                    : "/id/investors/"
                                  : item.url === "/about-us/board-of-directors"
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : item.url === "/living-lab-ventures"
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : `${parseLink(item.url)}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItems &&
                    investorsItems.map((item, i) => {
                      if (i >= 5) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? lang === "EN"
                                    ? `/investors/`
                                    : "/id/investors/"
                                  : item.url === "/about-us/board-of-directors"
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : item.url === "/living-lab-ventures"
                                  ? lang === "EN"
                                    ? `${item.url}/`
                                    : `/id${item.url}/`
                                  : `${parseLink(item.url)}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to={
                        lang === "EN"
                          ? `/investors/corporate-structure/`
                          : `/id/investors/corporate-structure/`
                      }
                    >
                      Corporate Structure
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Megamenu Investors Id */}
      {!isAmp && (
        <div
          id="Investor"
          className={`megamenu-desktop d-none d-block ${
            /* Disabled because ID dropdown has no value and already handle from EN */
            /* lang === "ID" && investorToggle.clicked ? "show" : "" */
            null
          }`}
          data-content
        >
          <div className="container">
            <div className="row">
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItemsId &&
                    investorsItemsId.map((item, i) => {
                      if (i < 6) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? `/id/investors/`
                                  : item.url === "/about-us/board-of-directors"
                                  ? `/id${item.url}/`
                                  : `/id/investors${item.url}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
              <div className="col-6 menu-area">
                <ul className="navbar-nav">
                  {investorsItemsId &&
                    investorsItemsId.map((item, i) => {
                      if (i >= 6) {
                        return (
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to={
                                item.url === "/general-information"
                                  ? `/id/investors/`
                                  : item.url === "/about-us/board-of-directors"
                                  ? `/id${item.url}/`
                                  : `${parseLink(item.url)}/`
                              }
                            >
                              {item.label}
                            </Link>
                          </li>
                        )
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NavbarDesktop
